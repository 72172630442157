<script lang="ts">
    import { getContext } from 'svelte';
    import axios from 'axios';
    import UserAuctionCard from '../../Pages/marketPlace/UserAuctionCard.svelte';
    const referralClaimContext = getContext('referralClaimContext');
    import * as Dialog from '$lib/components/ui/dialog';
    import toast from 'svelte-french-toast';
    const {
        authUser,
        userWhoAreNotReferred,
        pusherKey,
        pusherCluster,
        socketsDriver,
        soketiHost,
        soketiPort,
        pusherScheme,
    } = referralClaimContext;
    import EllipsisHorizontalIcon from '../../Icons/EllipsisHorizontalIcon.svelte';
    import { onMount } from 'svelte';
    let modalElement;
    let modalInstance;
    $: auctionId = '';
    $: filterOption = '5';
    let open = false;
    let bidopen = false;



    function placeBid(id) {
        // Disable the button and show the spinner
        document.querySelector(`#spinner_${id}`).style.display = 'inline-block';

        const placeBidButton = document.querySelector(`#button_${id}`);
        placeBidButton.querySelector('.buttonText').style.display = 'none';

        const formId = id;
        const currentPrice = document.querySelector(`#current_price_${formId}`).value;
        const currentBidderUserId = document.querySelector(`#bidder_user_id_${formId}`).value;

        axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
        axios.defaults.headers.common['X-CSRF-TOKEN'] = document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute('content');

        const dataToSend = {
            bidder_user_id: currentBidderUserId,
            user_id: id,
            current_price: currentPrice,
        };

        axios
            .post('/referral-claim/place-bid', dataToSend)
            .then((response) => {
                placeBidButton.disabled = false;
                placeBidButton.querySelector('.spinner').style.display = 'none';
                placeBidButton.querySelector('.buttonText').style.display = 'inline-block';

                initTimer(response.data.validity, response.data.nowTime, response.data.user_id);
                document.querySelector(`#current_bidder_name_${response.data.user_id}`).textContent =
                    response.data.current_bidder.name;
                document.querySelector(`#current_bid_price_${response.data.user_id}`).textContent =
                    response.data.current_bid_price;
                document.querySelector(`#min_price_${response.data.user_id}`).textContent =
                    response.data.next_minimum_price;
                document.querySelector(`#current_price_${response.data.user_id}`).value =
                    response.data.next_minimum_price;

                toast.success(response.data.message);
            })

            .catch((error) => {
                placeBidButton.disabled = false;
                placeBidButton.querySelector('.spinner').style.display = 'none';
                placeBidButton.querySelector('.buttonText').style.display = 'inline-block';

                if (error.response && error.response.status === 422) {
                    const errors = error.response.data.error;
                    toast.error(errors);
                } else {
                    toast.error(error.message);
                }
            });
    }

    onMount(() => {
        modalInstance = new bootstrap.Modal(modalElement);
    });

    function showModal() {
        modalInstance.show();
    }

    function hideModal() {
        modalInstance.hide();
    }

    function toggleDropdown() {
        open = !open;
    }

    function selectOption(id) {
        filterOption = id;
        open = false;
    }

    function openModal(id) {
        auctionId = id;
        showModal();
    }

    function setReminder(id) {
        axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
        axios.defaults.headers.common['X-CSRF-TOKEN'] = document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute('content');

        const dataToSend = {
            auction_id: auctionId,
            validity: filterOption,
        };
        axios
            .post('/market/setreminder', dataToSend)
            .then((response) => {
                toast.success(response.data.message);
                hideModal();
            })
            .catch((error) => {
                console.log(error);
                if (error.response) {
                    if (error.response.status === 422) {
                        const errors = error.response.data.errors;
                        toast.error(errors);
                    } else if (error.response.status === 400) {
                        const errorMessage = error.response.data.error;
                        toast.error(errorMessage);
                    } else {
                        toast.error('An error occurred.');
                    }
                } else {
                    toast.error('Network error occurred.');
                }
            });
    }

    function showBidModel() {
        bidopen = true;
    }
</script>

{#if userWhoAreNotReferred.length > 0}
    {#each userWhoAreNotReferred as item}


        {#if item.user_id != authUser}

        <svelte:component
        this={UserAuctionCard}
        item={item}
        on:childClick={openModal}
        on:postBidClick={showBidModel}
    />
        {/if}
    {/each}
{:else}
    <h4>There is no user</h4>
{/if}

<!-- {#if userWhoAreNotReferred.length > 0}
    {#each userWhoAreNotReferred as user}
        {#if user.id != authUser}
            <div class="m-3" id={"referral_" + user.id}>
                <div class="mb-2 d-flex justify-between">
                  <span>
                    bid for
                    <b><a href={window.location.host + "/" + user.username} target="blank">{user.name}</a></b>
                  </span>
                    <span>
                      <div class="dropdown dropleft">
                        <a class="btn btn-sm text-dark-r text-hover btn-outline-dark px-2 py-1 m-0" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
                          <svelte:component this={EllipsisHorizontalIcon}/>
                        </a>
                        <div class="dropdown-menu">
                          <a class="dropdown-item addAlertBtn" on:click={() => openModal(user.auction.id)} href="javascript:void(0);" data-auction-id={user.auction.id}>Add Alert</a>
                        </div>
                      </div>
                    </span>
                </div>
                <h4>
                  user info

                </h4>
                <div>Total Post : {user.posts_count}</div>
                <div>Total Reactions : {user.reactions_count}</div>
                <div>Total Subscribers : {user.subscribers_count}</div>
                <hr />
                {#if user.auction && user.auction.latest_bid}
                    Current Bidder :  <b id={"current_bidder_name_" + user.id }> { user.auction?.latest_bid?.bidder.name } </b> <br>
                    Current Bid price : <span> <b id={"current_bid_price_" + user.id }> { user.auction.latest_bid.amount } </b></span> <br>
                    <div> Minimum Price For bid: <b>  <span id={"min_price_"+user.id}> {minimumPriceCalculator(user.auction.latest_bid.amount)} </span>  </b></div> <br>

                    {initTheTimer(user.auction.latest_bid.bid_validity,user.id)}
                {:else}
                    Current Bidder :  <b id={"current_bidder_name_" + user.id }>  </b> <br>
                    Current Bid price : <span> <b id={"current_bid_price_" + user.id }> 0</b></span>
                    <div> Minimum Price For bid: <b>  <span id={"min_price_"+user.id}> 0 </span>  </b></div> <br>
                {/if}
                <div> time left: <span id={"timer_"+user.id}> </span> </div>
                <form  method="post" id={"form_"+user.id}>
                    <input type="number"  step="0.1" id={"bidder_user_id_"+user.id} name="bidder_user_id" value={authUser} class="form-control" hidden> <br>
                    <input type="number" id={"user_id_"+user.id}  name="user_id" value={user.id} class="form-control" hidden> <br>

                    {#if user.auction && user.auction.latest_bid}
                        <label class="form-label" for="current_price">Enter your Bid Price</label>
                        <input type="text" name="current_price" id={"current_price_"+user.id} value={minimumPriceCalculator(user.auction.latest_bid.amount)} min={user.auction.latest_bid.amount} class="form-control"> <br>
                    {:else}
                        <label class="form-label" for="current_price">Enter your Bid Price</label>
                        <input type="text" id={"current_price_"+user.id}  name="current_price" value="0.1" class="form-control"> <br>
                    {/if}

                    <button type="button" id={"button_" + user.id} on:click={() => placeBid(user.id)} class="btn btn-sm btn-success" formId={user.id} >




                        <span id={"spinner_"+user.id} class="spinner" style="display: none">

                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                <path fill="none" d="M0 0h24v24H0z"/>
                                <path d="M15 4v7h5V4h-5zM4 15h5v5H4v-5zM4 4v5h5V4H4zm11 11h5v5h-5v-5z" fill="currentColor"/>
                                <path d="M8 8v8h8V8H8zm7 7H9V9h6v6z" fill="currentColor"/>
                              </svg>

                        </span>
                        <span class="buttonText">Place Bid</span>



                    </button>





                </form>

            </div>
        {/if}
    {/each}
{:else}
    <h4>There is no user</h4>
{/if} -->

<div bind:this="{modalElement}" class="modal fade" tabindex="-1" role="dialog" id="addAlert">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Add Alert</h5>
                <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    on:click="{() => {
                        hideModal;
                    }}"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label for="gender" class="m-0">Minute before This Auction Close</label>
                    <div class="relative block text-left">
                        <div>
                            <button
                                type="button"
                                class="inline-flex w-full items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                aria-expanded="{open}"
                                aria-haspopup="true"
                                on:click="{toggleDropdown}"
                            >
                                {filterOption} Min
                                <svg
                                    width="20px"
                                    height="20px"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    class="ui-svg-inline ml-2-5"
                                    ><path
                                        d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"
                                    ></path></svg
                                >
                            </button>
                        </div>
                        <div
                            class=" absolute left-0 z-10 mt-2 w-full overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition-all duration-500"
                            class:h-0="{!open}"
                            class:h-auto="{open}"
                            class:scale-50="{!open}"
                            class:scale-100="{open}"
                            class:opacity-0="{!open}"
                            class:opacity-100="{open}"
                        >
                            <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                <a class="dropdown-elem" role="menuitem" on:click="{() => selectOption('5')}"> 5 Min</a>
                                <a class="dropdown-elem" role="menuitem" on:click="{() => selectOption('10')}">10 Min</a
                                >
                                <a class="dropdown-elem" role="menuitem" on:click="{() => selectOption('15')}">15 Min</a
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-warning" on:click="{() => setReminder()}">Submit</button>
            </div>
        </div>
    </div>
</div>

<Dialog.Root bind:bidopen>
    <Dialog.Content>
        <Dialog.Header>
            <Dialog.Title>Are you sure absolutely sure?</Dialog.Title>
            <Dialog.Description>
                This action cannot be undone. This will permanently delete your account and remove your data from our
                servers.
            </Dialog.Description>
        </Dialog.Header>
    </Dialog.Content>
</Dialog.Root>
